

if ($(window).width() > 1024) {
    $('.team-item').hover(function () {
        $(this).addClass('active');
        $(this).siblings().removeClass('active');
    });
} else {
    $('.team-item').on('click', function () {
        $(this).addClass('active');
        $(this).siblings().removeClass('active');
        $(this).siblings().find('.team-item__bottom').slideUp(300);
        $(this).find('.team-item__bottom').slideDown(300);
    });
}





