const partnersSwiper = new Swiper(".partners-swiper", {
    slidesPerView: 5,
    spaceBetween: 0,
    speed: 600,
    grid: {
        rows: 2,
    },
    pagination: {
        el: ".partners .swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".partners .swiper-button-next",
        prevEl: ".partners .swiper-button-prev",
    },
    breakpoints: {
        1024: {
            slidesPerView: 5,
            grid: {
                rows: 2,
            },
        },
        300: {
            slidesPerView: 2,
            grid: {
                rows: 5,
            },
        },
    },
});


const servicesSwiper = new Swiper(".services-swiper", {
    slidesPerView: 1,
    spaceBetween: 30,
    autoHeight: true,
    speed: 1000,
    pagination: {
        el: ".services .swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".services .swiper-button-next",
        prevEl: ".services .swiper-button-prev",
    },
});


let valuesSwiperDirection = "vertical";

if (window.innerWidth < 1100) {
    valuesSwiperDirection = "horizontal";
}


const valuesSwiper = new Swiper(".values-swiper", {
    slidesPerView: 1,
    spaceBetween: 60,
    direction: valuesSwiperDirection,
    loop: true,
    speed: 800,
    pagination: {
        el: ".values .swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".values .swiper-button-next",
        prevEl: ".values .swiper-button-prev",
    },
    breakpoints: {
        1100: {
            spaceBetween: 60,
        },
        300: {
            spaceBetween: 30,
            autoHeight: true,
        },
    },
});