gsap.registerPlugin(ScrollTrigger);




/* function valuesAnim() {
    gsap.set('.values__wrap-item', { opacity: 0 });

    const valuesItems = gsap.utils.toArray('.values__wrap-item');
    const pagination = document.getElementById('pagination');
    const dots = [];

    // Создаем точки пагинации
    valuesItems.forEach((_, i) => {
        const dot = document.createElement('div');
        dot.classList.add('dot');
        if (i === 0) dot.classList.add('active');
        pagination.appendChild(dot);
        dots.push(dot);
    });

    let isMobile = window.matchMedia("(max-width: 1024px)").matches;

    let tl = gsap.timeline({
        scrollTrigger: {
            trigger: isMobile ? '.values' : '.values__wrapper',
            start: 'top top',
            end: () => `+=${valuesItems.length * 900}`,
            scrub: 1,
            pin: true,
            pinSpacing: true,
            onUpdate: self => {
                const index = Math.round(self.progress * (valuesItems.length - 1));
                console.log(index);
                dots.forEach((dot, i) => {
                    dot.classList.toggle('active', i === index);
                });
            }
        },
    });

    valuesItems.forEach((item, i) => {
        tl.fromTo(item,
            { opacity: 0, y: 200 },
            { opacity: 1, y: 0, duration: 3 }
        )
            .to(item,
                { opacity: 0, y: -200, duration: 3 },
                "+=2"
            );
    });
}

valuesAnim(); */


//////////////////////////////////////////////////////////

/* function servicesAnim() {
    gsap.set('.services-item', { opacity: 0 });
    gsap.set('.services-item:first-child', { opacity: 1 });
    gsap.set('.services-dot:first-child', { className: "+=active" });  // set first dot as active

    const valuesItems = gsap.utils.toArray('.services-item');
    const dots = gsap.utils.toArray('.services-dot');

    let tl = gsap.timeline({
        scrollTrigger: {
            trigger: '.services',
            start: 'top top',
            end: () => `+=${valuesItems.length * 900}`,
            scrub: 1,
            pin: true,
            pinSpacing: true
        },
    });

    valuesItems.forEach((item, i) => {
        tl.fromTo(item,
            { opacity: 0, },
            {
                opacity: 1, duration: 3,
                onStart: function () {
                    dots.forEach(dot => dot.classList.remove('active'));
                    dots[i].classList.add('active');
                }
            }
        )
            .to(item,
                { opacity: 0, duration: 3 },
                "+=2"
            );
    });

    dots.forEach((dot, i) => {
        dot.addEventListener('click', function () {
            gsap.killTweensOf(valuesItems);
            gsap.set(valuesItems, { opacity: 0 });
            gsap.to(valuesItems[i], { opacity: 1, duration: 1 });
            dots.forEach(dot => dot.classList.remove('active'));
            dot.classList.add('active');
        });
    });
}

servicesAnim(); */










