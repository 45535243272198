function menu() {
    const burger = document.querySelector('.burger');
    const menu = document.querySelector('.nav');
    const menuLink = document.querySelectorAll('.nav a');

    menuLink.forEach(function (item) {
        item.addEventListener('click', function () {
            burger.classList.remove('active');
            menu.classList.remove('active');
            document.body.classList.remove('scroll-hide');
        });
    });

    burger.addEventListener('click', function () {
        this.classList.toggle('active');
        menu.classList.toggle('active');
        document.body.classList.toggle('scroll-hide');
    });

}

menu();