$('a.scroll-to').on('click', function (e) {
    e.preventDefault();

    const headerHeight = $('header').outerHeight();
    const target = $($(this).attr('href'));
    const elementPosition = target.offset().top;
    const offsetPosition = elementPosition - headerHeight;
    const animationDuration = 3000; // Время анимации в миллисекундах

    $('html, body').animate({
        scrollTop: offsetPosition
    }, animationDuration);
});